<template>
  <div>
    <b-form-group>
      <label class="required-control"> Cửa hàng:<b class="text-danger">*</b> </label>
      <treeselect
        ref="store-option"
        :load-options="loadOptionStores"
        :options="optionStores"
        :auto-load-root-options="false"
        :multiple="false"
        placeholder="Nhập cửa hàng"
        retryText="Thử lại..."
        retryTitle="Nhấp thử lại"
        loadingText="Đang tải..."
        noResultsText="Không có kết quả"
        :match-keys="['label', 'shortName']"
        v-model="order.storeId"
        @select="onSelectStore"
      />
      <b-form-invalid-feedback :state="validateState('searchStore')">
        Vui lòng nhập cửa hàng
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group>
      <label class="required-control"> Nguồn đơn hàng:<b class="text-danger">*</b></label>
      <treeselect
        :load-options="loadOptionOrderSources"
        :options="optionOrderSources"
        :auto-load-root-options="false"
        :multiple="false"
        placeholder="Nhập nguồn đơn hàng"
        retryText="Thử lại..."
        retryTitle="Nhấp thử lại"
        loadingText="Đang tải..."
        noResultsText="Không có kết quả"
        :match-keys="['label']"
        :value="order.orderSourceName"
        v-model="order.orderSourceId"
        openDirection="bottom"
      />
      <b-form-invalid-feedback :state="validateState('searchStore')">
        Vui lòng nhập nguồn đơn hàng
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group>
      <label class="required-control"> Loại đơn:<b class="text-danger">*</b> </label>
      <treeselect
        :options="optionTypes"
        :multiple="false"
        placeholder="Nhập loại đơn"
        noResultsText="Không có kết quả"
        :match-keys="['label', 'custom']"
        :value="order.orderType"
        v-model="order.orderType"
        openDirection="bottom"
      >
        <label
          slot="option-label"
          slot-scope="{ node, labelClassName }"
          :class="labelClassName"
        >
          <span :title="node.label"> {{ node.label }}</span>
        </label>
      </treeselect>
      <b-form-invalid-feedback :state="validateState('searchStore')">
        Vui lòng nhập nguồn đơn hàng
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group>
      <label>Trạng thái đơn:<b class="text-danger">*</b></label>
      <treeselect
        :options="optionStatus"
        :multiple="false"
        placeholder="Nhập trạng thái đơn"
        noResultsText="Không có kết quả"
        :match-keys="['label', 'custom']"
        v-model="order.status"
        openDirection="bottom"
      >
        <div slot="value-label" slot-scope="{ node }">
          {{ node.raw.customLabel }}
        </div>
      </treeselect>
    </b-form-group>
    <b-form-group v-show="[7, 8, 11].includes(+order.status)">
      <label>Lý do huỷ:</label>
      <treeselect
        :options="optionCancelStatus"
        :multiple="false"
        placeholder="Nhập lý do huỷ"
        noResultsText="Không có kết quả"
        :match-keys="['label', 'custom']"
        :value="order.cancelStatus"
        v-model="order.cancelStatus"
        openDirection="bottom"
      >
        <label
          slot="option-label"
          slot-scope="{ node, labelClassName }"
          :class="labelClassName"
        >
          <span :title="node.label"> {{ node.label }}</span>
        </label>
      </treeselect>
    </b-form-group>

    <b-form-group>
      <label>Trạng thái thanh toán:</label>
      <treeselect
        :options="optionPaymentStatus"
        :multiple="false"
        placeholder="Nhập trạng thái đơn"
        noResultsText="Không có kết quả"
        :match-keys="['label', 'custom']"
        v-model="order.partnerPaymentStatus"
        openDirection="bottom"
      >
        <div slot="value-label" slot-scope="{ node }">
          {{ node.raw.customLabel }}
        </div>
      </treeselect>
    </b-form-group>
    <b-form-group>
      <label v-if="checkCustomerCome"> Ngày khách nhận hàng </label>
      <label v-else> Ngày đến cửa hàng: </label>

      <div class="input-group">
        <date-picker
          placeholder="Ngày đến cửa hàng"
          class="form-control-sm input-style"
          :config="dpConfigs.date"
          v-model="order.checkDate"
          :wrap="true"
        ></date-picker>
        <div class="input-group-append">
          <button
            class="btn btn-secondary btn-sm datepickerbutton"
            type="button"
            title="Toggle"
          >
            <i class="far fa-calendar"></i>
          </button>
        </div>
      </div>
    </b-form-group>

    <b-form-group>
      <label v-if="checkCustomerCome"> Giờ khách nhận hàng</label>
      <label v-else> Giờ đến cửa hàng </label>
      <div class="input-group">
        <date-picker
          :config="dpConfigs.time"
          class="form-control-sm input-style"
          v-model="order.checkTime"
          :wrap="true"
          placeholder="Giờ đến cửa hàng"
        ></date-picker>
        <div class="input-group-append">
          <button
            class="btn btn-secondary btn-sm datepickerbutton"
            type="button"
            title="Toggle"
          >
            <i class="far fa-clock"></i>
          </button>
        </div>
      </div>
    </b-form-group>

    <b-form-group>
      <label>Mã vận đơn</label>
      <b-form-input
        class="input-style"
        size="sm"
        v-model="order.codeShip"
        placeholder="Mã vận đơn"
        autocomplete="off"
      ></b-form-input>
    </b-form-group>
  </div>
</template>

<script>
import apiCommonMixin from '@/view/mixins/api-common.mixins.js';
import linkMixin from '@/view/mixins/link.mixins.js';
import { validationMixin } from 'vuelidate';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
// Import datePicker component
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import {
  ORDER_STATUS,
  ORDER_STATUS_CR,
  ORDER_STATUS_SHOP,
  ORDER_STATUS_DP,
  ORDER_TYPE,
  ORDER_CANCEL_STATUS,
  PAYMENT_STATUS,
} from '@/utils/constants';
import {
  checkJobTitle,
  getEmployeeFromLocal,
  reduceDataTreeSelect,
  removeAccents,
} from '@/utils/common';
import { mapGetters } from 'vuex';
import { HANDLE_SET_ORDER_INFO } from '@/core/services/store/modules/order/order-info.module.js';
import timeUtils from '@/utils/date';

export default {
  mixins: [validationMixin, apiCommonMixin, linkMixin],
  props: {
    action: String,
  },
  components: {
    Treeselect,
    datePicker,
  },
  data() {
    return {
      dpConfigs: null,
      order: null,
      optionStores: null, // treelect default null
      optionOrderSources: null,
      optionTypes: null,
      optionStatus: null,
      optionCancelStatus: null,
      optionPaymentStatus: null,
      userInfor: null,
    };
  },
  watch: {
    order: {
      handler(value) {
        this.$store.dispatch(HANDLE_SET_ORDER_INFO, value);
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(['getOrderInfo']),
    checkCustomerCome() {
      return [3, 4].includes(Number(this.order.orderType));
    },
  },
  created() {
    this.mappingEnum();

    this.order = this.getOrderInfo;

    if (this.action === 'update') {
      this.optionStores = [
        {
          id: this.getOrderInfo.storeId,
          label: this.getOrderInfo.storeName,
        },
      ];

      this.optionOrderSources = [
        {
          id: this.getOrderInfo.orderSourceId,
          label: this.getOrderInfo.orderSourceName,
        },
      ];
      const status = this.getOrderInfo.status || null;
      const statusName = this.getOrderInfo.statusName || null;
      const hasStatus = this.optionStatus.find(
        (o) => status && Number(o.id) === status
      );

      if (!hasStatus) {
        this.optionStatus.unshift({
          id: status,
          label: statusName,
          custom: removeAccents(statusName),
          customLabel: statusName,
        });
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.optionStores = null;
      this.optionOrderSources = null;
    });
  },
  methods: {
    onSelectStore(node) {
      if (node.id && node.id !== this.order.storeId) {
        this.order.storeId = node.id;
        this.order.storeName = node.label;
      } else {
        this.order.storeId = null;
        this.order.storeName = null;
      }
    },
    mappingEnum() {
      this.dpConfigs = timeUtils.DP_CONFIG;
      this.userInfor = getEmployeeFromLocal();
      this.optionTypes = reduceDataTreeSelect(ORDER_TYPE, 'id', 'name');

      this.optionStatus = this.getStatusByJobTitle();

      this.optionCancelStatus = reduceDataTreeSelect(
        ORDER_CANCEL_STATUS,
        'value',
        'text'
      );

      this.optionPaymentStatus = reduceDataTreeSelect(
        PAYMENT_STATUS,
        'id',
        'name'
      );
    },
    getStatusByJobTitle() {
      const jobTitleId = this.userInfor.jobTitleId;

      if (checkJobTitle().isCR(jobTitleId)) {
        return reduceDataTreeSelect(ORDER_STATUS_CR, 'value', 'text');
      } else if (checkJobTitle().isShop(jobTitleId)) {
        return reduceDataTreeSelect(ORDER_STATUS_SHOP, 'value', 'text');
      } else if (checkJobTitle().isDP(jobTitleId)) {
        return reduceDataTreeSelect(ORDER_STATUS_DP, 'value', 'text');
      } else {
        return reduceDataTreeSelect(ORDER_STATUS, 'value', 'text');
      }
    },
    validateState(name) {
      if (!name) return null;
      //   const { $dirty, $error } = this.$v[name];
      //   return $dirty ? !$error : null;
    },
    async loadOptionStores() {
      const result = await this.fetchStores();

      this.optionStores = this.mapDataTreeSelect(result.data, [
        'id',
        'name',
        'shortName',
      ]);
    },
    async loadOptionOrderSources() {
      const result = await this.fetchOrderSources();
      this.optionOrderSources = this.mapDataTreeSelect(result.data, [
        'id',
        'name',
      ]);
    },
    mapDataTreeSelect(items = [], keys = []) {
      return items.map((element) => {
        return {
          id: element[keys[0]],
          label: element[keys[1]],
          shortName: element[keys[2]],
        };
      });
    },
  },
};
</script>