const moment = require('moment');
const { DATETIME_FORMAT } = require('@/utils/constants');
const DATE_FORMAT = 'DD/MM/YYYY';
const YEAR_MONTH_DAY_FORMAT = 'YYYY-MM-DD';
const DATETIME_DB_FORMAT = 'YYYY-MM-DD HH:mm:ss';

const DP_CONFIG = {
  date: {
    format: 'DD/MM/YYYY',
    useCurrent: false,
    showClear: true,
    showClose: true,
    minDate: false,
    maxDate: false,
    locale: 'vi',
    sideBySide: true,
    allowInputToggle: true,
  },
  time: {
    format: 'LT',
    useCurrent: true,
    sideBySide: true,
    locale: 'vi',
    allowInputToggle: true,
  },
};

const DP_CONFIG_DATETIME = {
  date: {
    format: DATETIME_FORMAT,
    useCurrent: false,
    showClear: true,
    showClose: true,
    minDate: false,
    maxDate: false,
    sideBySide: true,
    locale: 'vi',
  },
};

const formatDate = (date, format) => {
  return moment(date).format(format || 'YYYY-MM-DD');
};

const formatSpecificDate = (date, currentFormat, newFormat) => {
  return date ? moment(date, currentFormat).format(newFormat) : null;
};

const formatToISODate = (date, currentFormat, toFormat = null) => {
  return moment(date, currentFormat).format(toFormat);
};

const getSubtractDate = (subtract = 0, format = DATE_FORMAT) => {
  return moment()
    .subtract(subtract, 'days')
    .format(format);
};

const getAddDate = (add = 0, format = DATE_FORMAT) => {
  return moment()
    .add(add, 'days')
    .format(format);
};

const getRemainingDays = (startDate, endDate, format = DATETIME_FORMAT) => {
  return moment(endDate, format).diff(moment(startDate, format), 'days');
};

const getStartOfDay = (date, format = DATE_FORMAT) => {
  const isValidDate = moment(date, format).isValid();
  return isValidDate
    ? moment(date, format)
        .startOf('day')
        .format(DATETIME_DB_FORMAT)
    : null;
};

const getEndOfDay = (date, format = DATE_FORMAT) => {
  const isValidDate = moment(date, format).isValid();
  return isValidDate
    ? moment(date, format)
        .endOf('day')
        .format(DATETIME_DB_FORMAT)
    : null;
};

module.exports = {
  formatDate,
  getSubtractDate,
  getAddDate,
  formatToISODate,
  DP_CONFIG,
  getRemainingDays,
  DP_CONFIG_DATETIME,
  YEAR_MONTH_DAY_FORMAT,
  formatSpecificDate,
  DATETIME_DB_FORMAT,
  getStartOfDay,
  getEndOfDay,
  DATE_FORMAT,
};
